import React from 'react';
import {Button, SPACE} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {PATH} from '@/routes/Router.config';

export const CreateProfileButton = () => {
  const {formatMessage} = useIntl();

  const handleOnCreateProfile = () => {
    window.location.href = PATH.QUIZ;
  };

  return (
    <Button onPress={handleOnCreateProfile} nativeID="HomeCtaBurgerMenu" marginBottom={SPACE.SPACE_4} small>
      {formatMessage({id: 'navigation.start.cta'})}
    </Button>
  );
};
