import {UI_EVENT} from '@/modules/EventManager';

const TRACKING_EVENT = {
  PAGE_VIEW: 'pageview',
  QUIZ: 'quiz',
  EXPERIMENT_APPLIED: 'experimentApplied',
  STYLE_CURATOR_SLIDER: 'style_curator_slider',
} as const;

export const SECTION = {
  OPEN_MENU: 'BurgerMenu',
  HOME: 'home',
} as const;

export const USER_TRACKING_EVENT_MAP: Partial<Record<UI_EVENT, string>> = {
  [UI_EVENT.PAGE_VIEW]: TRACKING_EVENT.PAGE_VIEW,
  [UI_EVENT.QUIZ]: TRACKING_EVENT.QUIZ,
  [UI_EVENT.EXPERIMENT_APPLIED]: TRACKING_EVENT.EXPERIMENT_APPLIED,
  [UI_EVENT.STYLE_CURATOR_SLIDER]: TRACKING_EVENT.STYLE_CURATOR_SLIDER,
};
