import {TinyEmitter} from 'tiny-emitter';

type EventCallback = (args: unknown[]) => void;

const eventEmitter = new TinyEmitter();

export enum UI_EVENT {
  PAGE_VIEW = 'page_view',
  QUIZ = 'quiz',
  EXPERIMENT_APPLIED = 'experiment_applied',
  STYLE_CURATOR_SLIDER = 'style_curator_slider',
}

export const eventManager = {
  emit: (event: UI_EVENT, ...args: unknown[]): void => {
    eventEmitter.emit(event, ...args);
  },
  subscribe: (event: UI_EVENT, callback: EventCallback): void => {
    eventEmitter.on(event, callback);
  },
  unsubscribe: (event: UI_EVENT, callback: EventCallback): void => {
    eventEmitter.off(event, callback);
  },
};
