export const experiments = {
  GRW_FREE_TRIAL_OUT_V2: {
    name: 'grw_freeTrialOutV2',
    variants: {
      control: 'grw_freeTrialOutV2_control',
      v1: 'grw_freeTrialOutV2_v1',
    },
  },
  GRW_FREE_TRIAL_OUT_AFTERMATH: {
    name: 'grw_freeTrialOut_Aftermath',
    variants: {
      control: 'grw_freeTrialOut_Aftermath_control',
      v1: 'grw_freeTrialOut_Aftermath_v1',
    },
  },
  GRW_JUN_24_TALENTS: {
    name: 'grw_Jun24Talents',
    variants: {
      control: 'grw_Jun24Talents_control',
      v1: 'grw_Jun24Talents_v1',
      v2: 'grw_Jun24Talents_v2',
    },
  },
  GRW_PRODUCT_SHOWCASE: {
    name: 'grw_productShowcase',
    variants: {
      control: 'grw_productShowcase_control',
      v1: 'grw_productShowcase_v1',
      v2: 'grw_productShowcase_v2',
    },
  },
} as const;
