export const inAppBrowser = (): boolean =>
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent) ||
  /Pinterest/.test(navigator.userAgent);

export const iOSInAppBrowser = (): boolean => window.screen.height > 667 && /FBIOS/.test(navigator.userAgent);

export const isAndroidInAppBrowser = (): boolean => inAppBrowser() && !iOSInAppBrowser();

export const IOS_FB_HEADER_OFFSET = 70;
